import React from "react"
import CoverStyles from "../../components/CoverStyles/coverStyles"
import Page from "../../components/page"
import pageStyles from "../../components/page.module.css"

export default function AlbumCoverStyles({ location, data }) {
  return (
    <Page title={"Album Cover Styles – PhotoTailors"} location={location}>
      <div className={pageStyles.sectionWrapper}>
        <div className={pageStyles.section}>
          <CoverStyles category={"album"} />
        </div>
      </div>
    </Page>
  )
}
