import React, { useState, useEffect } from "react"
import styles from "./coverStyles.module.css"

const CoverStyles = ({ category }) => {
  const [covers, setCovers] = useState([])
  useEffect(() => {
    fetch("https://shop.phototailors.com/api/public/styles")
      .then(response => response.json())
      .then(covers => setCovers(covers[category]))
  }, [])

  return (
    <div className={styles.coversList}>
      {covers.map(cover => {
        return (
          <div className={styles.cover}>
            <div className={styles.coverImage}>
              <img src={cover.image} alt="cover type" />
            </div>
            <div className={styles.coverTitle}>{cover.name}</div>
          </div>
        )
      })}
    </div>
  )
}

export default CoverStyles
